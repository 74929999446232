import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../../components/layout'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const ServiceWithTouchpoints = props => (
  <Layout>
    <Helmet
      title="A service with 5000 touchpoints"
      meta={[
        {
          name: 'Article aboout a service in Mumbai, India',
          content: 'Articles',
        },
      ]}
      bodyAttributes={{
        class: 'articles',
      }}
    />

    <div id="main" className="alt">
      <section id="article-banner">
        <div>
          <span className="image main">
            <Img fluid={props.data.coverTouchpoints.childImageSharp.fluid} />
          </span>
        </div>
      </section>
      <div class="post-wrapper">
        <div class="post-content">
          <header>
            <h1>a service with 5000 touchpoints.</h1>
            <h3>
              The Mumbai Dabbawala (lunchbox carrier) service has been
              accredited by Harvard as a six sigma service, but can it be
              further innovated? Here is my take on it.
            </h3>
          </header>
          <section>
            <small>MARCH 2017 - 12 mins read</small>
            <p className="full-text">
              Born and brought up in Mumbai, India, i have seen the dabbawalas
              all my life. I have never really given a thought into how this 125
              year old service functions or how it can be redesigned, until i
              got into the service design field.
            </p>
            <p className="full-text">
              They are a very important part of life in Mumbai as they are the
              ones who make sure that people get their lunch in time at their
              offices or schools, everyday. Now what is a dabba? A dabba is
              simply a tiffin box (iconic stackable tin box) carrying
              home-cooked food, which is to be delivered from the home to a
              person in an office or a school. And a dabbawala is a person who
              picks up the food from the home, and ensures that it is delivered
              to the right office, to the right person on time. Every day, for a
              very modest fee, the dabbawalas collect freshly cooked meals from
              their customers’ homes all over Mumbai. Travelling by train, bus
              and bicycle, they then sort and deliver each of them to offices
              and workplaces throughout the city by lunchtime – and even return
              the used tiffins back to the customer’s home for reuse. It all may
              sound fairly simple, but imagine doing this in one of the most
              densely populated cities in the world.
            </p>
            <div className="image">
              <Img fluid={props.data.touchpoints1.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              The dabbawala are an incredible organization of over 5000 people
              in Mumbai. They deliver nearly 2,00,000 lunches everyday and on
              time and what is even more impressive is the no usage of any
              technology. The only motorized transport used is the Mumbai local
              trains, other than that they make use of hand-drawn carts and
              bicycles. To satisfy their customers, they use a complex system of
              collection teams, sorting points and delivery zones, and a
              completely manual system for routing the right meal to the right
              destination. This labelling system must rely purely on numbers and
              colors, painted on the tiffin - because most of the dabbawalas are
              illiterate and cannot read. Despite challenges like this, the
              organization has been recognized and celebrated for their amazing
              order accuracy estimated at roughly 1 error in every 16 million
              transactions and has resulted in them being recognized by Forbes
              as being a Six Sigma organization. While the operations are fairly
              simple, the dabbawalas are now beginning to work with newer
              technologies. You can SMS them, or you can also log on to their
              site, mydabbawala.com to avail of their services
            </p>
            <div className="two-sidebyside">
              <div className="img left">
                <Img fluid={props.data.touchpoints2.childImageSharp.fluid} />
              </div>
              <div className="img right">
                <Img fluid={props.data.touchpoints3.childImageSharp.fluid} />
              </div>
            </div>
            <p className="full-text">
              One of the most disturbing dichotomies of a fast growing
              metropolitan city like Mumbai is the unequal distribution of
              wealth, privileges and even civic amenities. To 1.6 million people
              who have lunch everyday in Mumbai from the dabbawallahs, there are
              close to 200,000 street and slum children who go without food for
              days. The Happy Life Welfare society decided to give it a shot and
              that's how the Share My Dabba initiative took off - a new idea for
              helping to feed Mumbai’s 200,000+ slum children. "Tonnes of food
              gets left over and uneaten. That amount of food can feed hundreds
              of children. That is the potential we are trying to work on," It
              was vital that the solution they developed together was simple,
              inexpensive, and does not risk delaying the dabbawala’s schedule
              or their record of on-time delivery.
            </p>
            <p className="full-text">
              So here’s how it works; anyone who doesn’t eat all of the food in
              their lunch can simply place a sticker saying “Share” on the
              tiffin of uneaten food.The dabbawala picks up the tiffins after
              lunch as usual, and take them back to a central redistribution
              location. Here volunteers meet them, quickly separate the tiffins
              with “Share” stickers, remove the food in them to give to the
              children, and then quickly repack the tiffins so the dabbawala can
              get them back to customers’ homes on schedule. The whole process
              takes less than 15 minutes. The food these children receive may be
              cold, but is otherwise perfectly good, and would otherwise be
              going to waste.
            </p>
            <div className="image">
              <Img fluid={props.data.touchpoints4.childImageSharp.fluid} />
            </div>
            <p className="full-text">
              Although i absolutely love the Share My Dabba initiative and the
              intent behind it, i feel that it lacks emotional empathy. The
              Share My Dabba works perfectly fine on the functional side of
              things as it get food left over in dabbas to hungry street
              children, using just a tiny Share sticker and the extensive
              dabbawala network, however it is not enough to think only about
              the functional aspects, but also think about what the emotional
              state of these street children when they get this food.
            </p>
            <p className="full-text">
              Its hurting the dignity of the poor as they are given messy,
              unwanted & leftover food. If you really want to feed a child then
              it shouldn’t be by your leftover food. The iconic form and design
              of the dabba (tiffin box) can be used to change that perspective.
              Instead of pasting the share sticker on entire tiffin box, one can
              place it on a particular section/compartment of the tiffin box.
              This section can contain food that is packed from the homes
              specially for these kids. During lunchtime the food in that
              particular section/compartment will be deliberately left unopened
              & untouched. The dabbawalas can pick up the dabbas after lunch
              according to their regular routine and take them to their central
              redistribution centres/ There volunteers from the Happy Life
              Welfare Society can open these untouched sections/compartments of
              dabbas which have the red share sticker on them and give it to the
              kids on the street.
            </p>
          </section>
          <section className="takeaways">
            <span class="project-step-number">01</span>
            <h2>takeaways</h2>
            <p className="full-text">
              When designing, it is absolutely vital to empathise with the end
              user. The ability to step into the shoes of the user, aiming to
              understanding their feelings & perspectives and to use that
              understanding to guide our actions. By empathising, a simple tweak
              in the design of the system will be enough to make the children
              feel cared for as the food has been cooked especially for them and
              not some messy leftovers from the lunch.
            </p>
          </section>
          {/* <section className="article-social">
            <div className="social inner">
              <p className="article-social-heading">share this post</p>
              <ul className="icons">
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://twitter.com/sagarsatam13"
                    className="icon alt fa-twitter"
                  >
                    <span className="label">Twitter</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/sagar.satam/"
                    className="icon alt fa-instagram"
                  >
                    <span className="label">Instagram</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://in.pinterest.com/sagarsatam/"
                    className="icon alt fa-pinterest"
                  >
                    <span className="label">Pinterest</span>
                  </a>
                </li>
                <li>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://www.linkedin.com/in/satamsagar/"
                    className="icon alt fa-linkedin"
                  >
                    <span className="label">LinkedIn</span>
                  </a>
                </li>
              </ul>
            </div>
          </section> */}
          <section id="footertopcta" className="inner">
            <p className="text arrow-back">
              head back to all
              <br />
              <a href="/my-thinking"> my thinking</a>
            </p>
          </section>
        </div>
      </div>
    </div>
    <div className="footer-top">
      <div className="inner">
        <div className="tiles__next">next story</div>
        <div className="tiles__wrapper">
          <div className="tiles__content">
            <div className="tiles__info">
              <a href="/articles/tools-for-designing-services">
                <h2 className="tiles__title">tools for designing services.</h2>
              </a>
              <small>SEPTEMBER 2016 ⠂8 mins read</small>
              <div className="tiles__text">
                Introduction of service design tools to a group of Engineers,
                Business & Marketing professionals. Guiding them through a
                workshop on how these tools can be used to analyse research and
                develop an user-centric idea that is ready to be tested in the
                market.
              </div>
            </div>
          </div>
          <div className="tiles__image">
            <a href="/articles/tools-for-designing-services">
              <Img fluid={props.data.otherarticle.childImageSharp.fluid} />
            </a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default ServiceWithTouchpoints

export const fluidTouchpointsImage = graphql`
  fragment fluidTouchpointsImage on File {
    childImageSharp {
      fluid(maxWidth: 920, quality: 100) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    coverTouchpoints: file(
      relativePath: { eq: "articles/touchpoints/cover-touchpoints.png" }
    ) {
      ...fluidTouchpointsImage
    }
    touchpoints1: file(
      relativePath: { eq: "articles/touchpoints/touchpoints1.png" }
    ) {
      ...fluidTouchpointsImage
    }
    touchpoints2: file(
      relativePath: { eq: "articles/touchpoints/touchpoints2.png" }
    ) {
      ...fluidTouchpointsImage
    }
    touchpoints3: file(
      relativePath: { eq: "articles/touchpoints/touchpoints3.png" }
    ) {
      ...fluidTouchpointsImage
    }
    touchpoints4: file(
      relativePath: { eq: "articles/touchpoints/touchpoints4.png" }
    ) {
      ...fluidTouchpointsImage
    }
    otherarticle: file(relativePath: { eq: "projects/tools.png" }) {
      ...fluidTouchpointsImage
    }
  }
`
